import { camelCaseToKebabCase as t } from "./string.js";
const s = new class {
  constructor() {
    if (this.supports = {
      js: !0
    }, !document.body.hasAttribute("cds-supports") || "no-js" === document.body.getAttribute("cds-supports")) {
      const s = t(Object.keys(this.supports).reduce((t, s) => `${t} ${this.supports[s] ? s : "no-" + s}`, "")).trim();
      document.body.setAttribute("cds-supports", s);
    }
  }
}();
export { s as browserFeatures };
