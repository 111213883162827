let t = !1;
function e(t) {
  if ((t = Math.trunc(t) || 0) < 0 && (t += this.length), !(t < 0 || t >= this.length)) return this[t];
}
if (!t) {
  t = !0;
  const r = Reflect.getPrototypeOf(Int8Array);
  for (const t of [Array, String, r]) Object.defineProperty(t.prototype, "at", {
    value: e,
    writable: !0,
    enumerable: !1,
    configurable: !0
  });
}
export { e as at };
