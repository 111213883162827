import { ClarityIcons as e } from "../icon.service.js";
function i(e) {
  return e.badge && ("inherit-triangle" === e.badge || "warning-triangle" === e.badge);
}
function r(e) {
  let r = "";
  return e.badge && i(e) ? r = '<path d="M26.85 1.14L21.13 11a1.28 1.28 0 001.1 2h11.45a1.28 1.28 0 001.1-2l-5.72-9.86a1.28 1.28 0 00-2.21 0z" class="alert" />' : e.badge && (r = '<circle cx="30" cy="6" r="5" class="badge" />'), r;
}
function t(r) {
  const t = e.registry[r.shape] ?? e.registry.unknown;
  let n = r.solid && t.solid ? t.solid : t.outline;
  return r.badge && !i(r) && (n = r.solid ? t.solidBadged ?? n : t.outlineBadged ?? n), i(r) && (n = r.solid ? t.solidAlerted ?? n : t.outlineAlerted ?? n), n;
}
export { r as getIconBadgeSVG, t as getIconSVG, i as hasAlertBadge };
