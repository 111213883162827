import { LogService as t } from "../services/log.service.js";
import { isString as e } from "./identity.js";
function n(t, ...e) {
  return e.filter(e => t.classList.contains(e)).length === e.length;
}
function r(t, ...e) {
  return e.forEach(e => {
    t.classList.add(e);
  }), t;
}
function o(t, ...e) {
  return e.forEach(e => {
    t.classList.remove(e);
  }), t;
}
function i(t, e, n) {
  return o(t, ...e.filter(t => n.indexOf(t) < 0));
}
function u(t, ...e) {
  return e.forEach(([e, n]) => {
    t.style[e] = n;
  }), t;
}
function a(t, ...e) {
  return e.forEach(e => {
    t.style[e] = "";
  }), t;
}
function c(t) {
  return `calc((${t} / var(--cds-global-base)) * 1rem)`;
}
function s(e, n = document.body, r = null) {
  try {
    return getComputedStyle(n, r).getPropertyValue(e).trim();
  } catch (e) {
    return t.warn("Container element passed to getCustomPropertyValue must be an element."), "";
  }
}
function l(e, n, r = document.body) {
  try {
    "" === n || null === n || !1 === n ? r.style.removeProperty(e) : r.style.setProperty(e, n);
  } catch (e) {
    t.warn("Container element passed to getCustomPropertyValue must be an element.");
  }
}
function d(t) {
  return !!t && e(t) && "--" === t.slice(0, 2);
}
function y() {
  return window.ShadowRoot && (void 0 === window.ShadyCSS || window.ShadyCSS.nativeShadow) && "adoptedStyleSheets" in Document.prototype && "replace" in CSSStyleSheet.prototype;
}
export { r as addClassnames, s as getCssPropertyValue, n as hasClassnames, d as isCssPropertyName, c as pxToRem, o as removeClassnames, i as removeClassnamesUnless, l as setCssPropertyValue, y as supportsAdoptingStyleSheets, a as unsetElementStyles, u as updateElementStyles };
