let n, e, o, t;
function r(e = !0) {
  if (!e || !n) {
    const e = document && document.querySelector("[ng-version]");
    n = e ? "" + e.getAttribute("ng-version") : void 0;
  }
  return n;
}
function i(o = !0) {
  return o && n || (e = window?.angular?.version?.full), e;
}
function u(n = !0) {
  return n && o || (o = window?.CDS?._react?.version ? window.CDS._react.version : document.querySelector("[data-reactroot], [data-reactid]") ? "unknown version" : void 0), o;
}
function c(n = !0) {
  if (!n || !t) {
    const n = document.querySelectorAll("*");
    let e;
    for (let o = 0; o < n.length; o++) if (n[o].__vue__) {
      e = n[o];
      break;
    }
    t = e ? "unknown version" : void 0;
  }
  return t;
}
function l() {
  return window?.location?.href?.includes("localhost:6006");
}
export { i as getAngularJSVersion, r as getAngularVersion, u as getReactVersion, c as getVueVersion, l as isStorybook };
