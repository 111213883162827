import t from "ramda/es/is";
import n from "ramda/es/isEmpty";
function r(t) {
  return null == t;
}
function e(t) {
  return r(t) || n(t);
}
function u(n) {
  return t(String, n);
}
function i(t) {
  return u(t) && !n(t.trim()) && +t == +t;
}
function c(n) {
  return t(String, n) || r(n);
}
function s(t) {
  return u(t) && !r(t) && !n(t);
}
function f(n) {
  return t(Object, n);
}
function a(n) {
  return t(Map, n);
}
function o(t) {
  return !e(t) && f(t);
}
function l(t, n) {
  return t !== n;
}
function d(t, n) {
  return c(t) && l(t, n);
}
function h(t, n) {
  return !e(t) && l(t, n);
}
function m(t) {
  return Object.values(t);
}
function p(t = "_") {
  return `${t}${Math.random().toString(36).substr(2, 9)}`;
}
function g(t, n) {
  return JSON.stringify(t) === JSON.stringify(n);
}
function y(t, n, r = `\${${t}}`) {
  return t.split(".").reduce((t, n) => {
    try {
      const e = t[n];
      switch (!0) {
        case null === e:
        case !1 === e:
        case "" === e:
        case 0 === e:
          return e;
        default:
          return e || r;
      }
    } catch {
      return r;
    }
  }, n);
}
function A(t) {
  const n = new Map();
  for (const [r, e] of t) a(e) ? n.set(r, A(e)) : n.set(r, e);
  return n;
}
function b(t) {
  switch (!0) {
    case a(t):
      return A(t);
    case f(t) && !Array.isArray(t):
      return x({}, t);
    default:
      return JSON.parse(JSON.stringify(t));
  }
}
function O(t) {
  switch (!0) {
    case "true" === t:
      return !0;
    case "false" === t:
      return !1;
    case "null" === t:
      return null;
    case "undefined" === t:
      return;
    case i(t):
      return +t;
    default:
      return t;
  }
}
function S(t) {
  return t.split(" ").map(t => t.split(":")).map(t => {
    const [n, r] = t;
    return [n, O(r)];
  });
}
function w(t, n, r) {
  if (!n) return !0;
  const e = S(n);
  if (!t) return !1;
  if (e.length < 1) return !0;
  {
    const n = e.filter(n => {
      const [r, e] = n;
      return $(t, r, e);
    });
    return "all" === r ? n.length === e.length : n.length > 0;
  }
}
function J(t, n) {
  return w(t, n, "all");
}
function N(t, n) {
  return w(t, n, "any");
}
function $(t, n, r) {
  if (n in t) return !1 === r ? !t[n] : t[n] === r;
  if (!t.hasAttribute || !t.hasAttribute(n)) return !t.hasAttribute && (!1 === r || void 0 === r) || !1;
  {
    const e = t.hasAttribute(n) && t.getAttribute(n);
    if (["null", "false", "undefined"].indexOf(e) > -1) switch (r) {
      case null:
        return "null" === e;
      case !1:
        return "false" === e;
      default:
        return !r;
    } else switch (r) {
      case !1:
        return !e || "false" === e;
      case !0:
        return "" === e || "true" === e;
      default:
        return e === r.toString();
    }
  }
}
function j(t, ...n) {
  return n.map(n => t(n)).indexOf(!1) < 0;
}
function v(...t) {
  return j(t => void 0 !== t, ...t);
}
function x(...t) {
  const n = {};
  return t.map(t => f(t) ? {
    ...t
  } : {}).forEach(t => {
    Object.keys(t).forEach(r => {
      const e = t[r];
      Array.isArray(e) ? n[r] = Array.from(e) : f(e) ? n[r] = x(n[r] || {}, e) : n[r] = e;
    });
  }), n;
}
export { j as allAre, v as allAreDefined, J as allPropertiesPass, w as anyOrAllPropertiesPass, N as anyPropertiesPass, O as convertAttributeStringValuesToValue, S as convertStringPropValuePairsToTuple, p as createId, b as deepClone, $ as doesPropertyPass, m as getEnumValues, y as getFromObjectPath, l as hasPropertyChanged, d as hasStringPropertyChanged, h as hasStringPropertyChangedAndNotNil, a as isMap, r as isNil, e as isNilOrEmpty, i as isNumericString, f as isObject, o as isObjectAndNotNilOrEmpty, u as isString, s as isStringAndNotNilOrEmpty, c as isStringOrNil, x as mergeObjects, g as objectNaiveDeepEquals };
