import { GlobalStateService as t } from "@cds/core/internal";
import { unknownIcon as e } from "./shapes/unknown.js";
class r {
  static get registry() {
    return {
      unknown: e[1],
      ...t.state.iconRegistry
    };
  }
  static addIcons(...e) {
    t.state.iconRegistry = {
      ...t.state.iconRegistry,
      ...Object.fromEntries(e.filter(([t]) => !r.registry[t]))
    };
  }
  static addAliases(...e) {
    const s = e.filter(([t]) => r.registry[t]).flatMap(([t, e]) => e.map(e => [e, r.registry[t]]));
    t.state.iconRegistry = {
      ...t.state.iconRegistry,
      ...Object.fromEntries(s)
    };
  }
  static getIconNameFromShape(t) {
    return t[0];
  }
}
export { r as ClarityIcons };
