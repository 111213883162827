import { isNilOrEmpty as t, isNumericString as n, getFromObjectPath as r } from "./identity.js";
function e(t, n, ...r) {
  return n.map(t => t(...r)).join(t).trim();
}
function u(t, ...n) {
  return e(" ", t, ...n);
}
function i(t, ...n) {
  return e("", t, ...n);
}
function c(t) {
  return t.replace(/[A-Z]/g, t => "-" + t.toLowerCase());
}
function o(t) {
  return t.split("-").map((t, n) => n ? t.charAt(0).toUpperCase() + t.slice(1).toLowerCase() : t).join("");
}
function f(t) {
  return a(o(t));
}
function s(t) {
  return l(Object.keys(t), t);
}
function p(t) {
  return l(Object.keys(t).filter(t => t.startsWith("--")), t);
}
function l(t, n) {
  return t.reduce((t, r) => `${t}${n[r] ? `${r}:${n[r]};` : ""}`, "");
}
function a(t) {
  return t.charAt(0).toUpperCase() + t.slice(1);
}
const h = "CSS Custom Properties",
  g = "Default Properties";
function m(t) {
  return k(t, "s");
}
function x(n, r, e = "prefix") {
  return !t(r) && !t(n) && ("prefix" === e ? n.substr(0, r.length) : n.substr(-1 * r.length)) === r;
}
function b(t, n) {
  return x(t, n, "prefix");
}
function j(t, n) {
  return x(t, n, "suffix");
}
function C(n, r, e = "prefix") {
  if (t(n)) return "";
  if (t(r) || !x(n, r, e)) return n;
  switch (e) {
    case "prefix":
      return n.substr(r.length);
    case "suffix":
      return n.substr(0, n.length - r.length);
    default:
      return n;
  }
}
function $(t, n) {
  return C(t, n, "prefix");
}
function d(t, n) {
  return C(t, n, "suffix");
}
function w(t, n, r = "") {
  const e = t.split(" "),
    u = "" === r ? e.filter(t => t !== n) : e.map(t => t === n ? r : t);
  return u.length > 0 ? u.join(" ") : "";
}
function y(t) {
  return t ? (t + "").trim().replace(/  +/g, " ") : "";
}
function A(t) {
  const n = y(t);
  return "" === n ? [] : n.split(" ");
}
function O(t, n, r) {
  return r ? Object.assign(n, r(t)) : n;
}
function k(t, r) {
  const e = t ? t.trim() : "";
  if ("" === e || !e.endsWith(r)) return 0;
  const u = e.slice(0, -1 * r.length);
  return n(u) ? +u : 0;
}
function L(t) {
  return t ? k(t.trim(), "px") : 0;
}
function P(t, n, e) {
  return t.replace(/\$\{.+?\}/g, t => {
    const u = t.substr(2, t.length - 3).trim();
    return r(u, n, e);
  });
}
export { c as camelCaseToKebabCase, a as capitalizeFirstLetter, O as convertStringPropertyToObjectConfig, h as cssGroup, m as getNumericValueFromCssSecondsStyleValue, P as interpolateNaively, b as isPrefixedBy, x as isPrefixedOrSuffixedBy, j as isSuffixedBy, o as kebabCaseToCamelCase, f as kebabCaseToPascalCase, L as pluckPixelValue, k as pluckValueFromStringUnit, g as propertiesGroup, $ as removePrefix, C as removePrefixOrSuffix, d as removeSuffix, w as replaceWord, p as setPropStyles, s as setStyles, A as transformSpacedStringToArray, u as transformToSpacedString, e as transformToString, i as transformToUnspacedString, y as trimExtraWhitespace };
