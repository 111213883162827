import { isNil as n } from "./identity.js";
function o(o = window) {
  return !n(o);
}
function t() {
  return void 0 !== globalThis?.process?.env?.JEST_WORKER_ID;
}
function e() {
  return !!t() || "test" === globalThis?.process?.env?.NODE_ENV && navigator?.userAgent?.includes("jsdom");
}
export { o as isBrowser, t as isJestTest, e as isJsdomTest };
