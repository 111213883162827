import { __decorate as t } from "tslib";
import { baseStyles as s, hasStringPropertyChangedAndNotNil as e, hasStringPropertyChanged as i, pxToRem as r, GlobalStateService as o, isString as p, property as n, state as h } from "@cds/core/internal";
import { LitElement as a, html as d, svg as c } from "lit";
import { query as l } from "lit/decorators/query.js";
import y from "./icon.element.scss.js";
import { ClarityIcons as u } from "./icon.service.js";
import { updateIconSizeStyle as g } from "./utils/icon.classnames.js";
import { getIconSVG as f, getIconBadgeSVG as m } from "./utils/icon.svg-helpers.js";
class v extends a {
  constructor() {
    super(...arguments);
    this._shape = "unknown", this.solid = !1, this.inverse = !1;
  }
  static get styles() {
    return [s, y];
  }
  get shape() {
    return this._shape;
  }
  set shape(t) {
    if (e(t, this._shape)) {
      const s = this._shape;
      this._shape = t, this.requestUpdate("shape", s);
    }
  }
  get size() {
    return this._size;
  }
  set size(t) {
    if (i(t, this._size)) {
      const s = this._size;
      this._size = t, g(this, t), this.requestUpdate("size", s);
    }
  }
  updated(t) {
    if (t.has("innerOffset") && this.innerOffset > 0) {
      const t = r(-1 * this.innerOffset),
        s = `calc(100% + ${r(2 * this.innerOffset)})`;
      this.svg.style.width = s, this.svg.style.height = s, this.svg.style.margin = `${t} 0 0 ${t}`;
    }
  }
  firstUpdated(t) {
    if (super.firstUpdated(t), this.isConnected) {
      let t = "unknown";
      this.subscription = o.stateUpdates.subscribe(s => {
        "iconRegistry" === s.key && u.registry[this.shape] && t !== this.shape && (t = this.shape, this.requestUpdate("shape"));
      });
    }
  }
  disconnectedCallback() {
    super.disconnectedCallback(), this.subscription?.unsubscribe();
  }
  render() {
    return p(u.registry[this.shape]) ? d`<span .innerHTML="${u.registry[this.shape]}"></span>` : c`<svg .innerHTML="${f(this) + m(this)}" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"></svg>`;
  }
}
t([n({
  type: String
})], v.prototype, "shape", null), t([n({
  type: String
})], v.prototype, "size", null), t([n({
  type: String
})], v.prototype, "direction", void 0), t([n({
  type: String
})], v.prototype, "flip", void 0), t([n({
  type: Boolean
})], v.prototype, "solid", void 0), t([n({
  type: String
})], v.prototype, "status", void 0), t([n({
  type: Boolean
})], v.prototype, "inverse", void 0), t([n({
  type: String
})], v.prototype, "badge", void 0), t([h({
  type: Number
})], v.prototype, "innerOffset", void 0), t([l("svg")], v.prototype, "svg", void 0);
export { v as CdsIcon };
