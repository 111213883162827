let e = !1,
  t = !1;
function o(e, t, o) {
  Object.defineProperty(e, o, {
    configurable: !0,
    enumerable: !0,
    get: function () {
      return this.hasAttribute(t) ? this.getAttribute(t) : null;
    },
    set: function (e) {
      null !== e ? this.setAttribute(t, e) : this.removeAttribute(t);
    }
  });
}
e || Element.prototype.hasOwnProperty("role") || (o(Element.prototype, "role", "role"), e = !0), t || Element.prototype.hasOwnProperty("ariaLabel") || (t = !0, ["ActiveDescendant", "Atomic", "AutoComplete", "Busy", "Checked", "ColCount", "ColIndex", "ColSpan", "Controls", "Current", "DescribedBy", "Details", "Disabled", "ErrorMessage", "Expanded", "FlowTo", "HasPopup", "Hidden", "Invalid", "KeyShortcuts", "Label", "LabelledBy", "Level", "Live", "Modal", "MultiLine", "MultiSelectable", "Orientation", "Owns", "Placeholder", "PosInSet", "Pressed", "ReadOnly", "Relevant", "Required", "RoleDescription", "RowCount", "RowIndex", "RowSpan", "Selected", "SetSize", "Sort", "ValueMax", "ValueMin", "ValueNow", "ValueText"].forEach(e => o(Element.prototype, "aria-" + e.toLowerCase(), "aria" + e)));
export { o as reflect };
