import t from "ramda/es/includes";
import e from "ramda/es/without";
import { getCssPropertyValue as n } from "./css.js";
import { transformSpacedStringToArray as r, pluckPixelValue as o } from "./string.js";
import { isStringAndNotNilOrEmpty as i, isNumericString as u } from "./identity.js";
function c(t) {
  switch (t.tagName.toLowerCase()) {
    case "input":
      return "hidden" !== t.getAttribute("type") && !t.hasAttribute("disabled") && !t.hasAttribute("readonly");
    case "button":
    case "select":
    case "textarea":
      return !t.hasAttribute("disabled");
    case "iframe":
    case "embed":
    case "object":
      return !0;
    case "a":
    case "area":
      return t.hasAttribute("href");
    case "audio":
    case "video":
      return t.hasAttribute("controls");
    default:
      return t.hasAttribute("tabindex") || "true" === t.getAttribute("contenteditable") || "button" === t.getAttribute("role") && !t.hasAttribute("disabled");
  }
}
function a(t) {
  if (!t) return !1;
  const e = t.getBoundingClientRect();
  return e.top < 0 || t.scrollHeight > t.offsetHeight || e.left < 0 || t.scrollWidth > t.clientWidth;
}
function s(t) {
  return t.querySelectorAll(f.join(", "));
}
const d = ["a[href]", "area[href]", "audio[controls]", "button:not([disabled])", 'input:not([type="hidden"]):not([disabled]):not([readonly])', "iframe", "object", "embed", "select:not([disabled])", "textarea:not([disabled])", "video[controls]", "*[contenteditable=true]", "[role=button]:not([disabled])"],
  l = ["*[tabindex]", ...d],
  f = ['*[tabindex]:not([tabindex="-1"])', ...d];
function b(t, e = "px") {
  return t && t.getBoundingClientRect ? t.getBoundingClientRect().width + e : "";
}
function h(t, e) {
  return e ? "" : b(t);
}
function m(t) {
  return !!t && t instanceof HTMLElement;
}
function A(t, e) {
  return !!t && t.hasAttribute(e) && i(t.getAttribute(e));
}
function g(t, e, n) {
  const [r, o] = e;
  n() ? p(t, [r, o]) : y(t, r);
}
function p(t, ...e) {
  t && e.forEach(([e, n]) => {
    !1 === n || null === n ? t.removeAttribute(e) : t.setAttribute(e, n + "");
  });
}
function y(t, ...e) {
  t && e.forEach(e => {
    t.removeAttribute(e);
  });
}
function x(e, n, r) {
  if (e) {
    const o = e.getAttribute(n);
    o ? t(r, o.split(" ")) || e.setAttribute(n, o + " " + r) : e.setAttribute(n, r);
  }
}
function w(t, n, o) {
  if (t) {
    const i = t.getAttribute(n);
    if (i) {
      const u = e([o], r(i)).join(" ");
      u ? t.setAttribute(n, u) : t.removeAttribute(n);
    }
  }
}
function E(...t) {
  t.forEach(t => {
    const [e, n] = t;
    e && p(e, ["slot", n]);
  });
}
function C(t) {
  return !!t && t?.offsetHeight > 0 && !1 === t?.hasAttribute("hidden");
}
function R(t) {
  Array.from(t).filter(t => t.textContent && t.textContent.trim().length > 0 && 3 === t.nodeType && t.parentElement).forEach(t => {
    const e = document.createElement("span");
    t.after(e), e.appendChild(t);
  });
}
function j(t, e) {
  return e && (t.querySelector(e) || t?.shadowRoot?.querySelector(e)) || null;
}
function H(t) {
  return document.createRange().createContextualFragment(t);
}
function q(t = window) {
  const e = t?.document,
    n = t?.innerHeight || e?.documentElement?.clientHeight || 0;
  return {
    width: t?.innerWidth || e?.documentElement?.clientWidth || 0,
    height: n
  };
}
function v(t) {
  const e = t || n("--cds-global-layout-width-xs").trim();
  return !!t?.endsWith("px") && o(e) >= q().width;
}
function S(t, e) {
  const n = e || t;
  return t.shadowRoot ? t.shadowRoot : n;
}
function W(t) {
  return u(t) ? "number" : t.match(/^\d{4}-\d{1,2}-\d{1,2}$/) ? "date" : "text";
}
function B(t, e) {
  const n = t.querySelectorAll(e),
    r = t.shadowRoot?.querySelectorAll(e);
  return [...Array.from(n), ...Array.from(r)];
}
function K(t) {
  return 2 === t.buttons && !t.ctrlKey || 1 === t.buttons && t.ctrlKey;
}
function L(t) {
  return null != t && "" + t != "false";
}
export { x as addAttributeValue, E as assignSlotNames, L as coerceBooleanProperty, K as contextMenuClick, H as createFragment, l as focusableSelectors, b as getElementWidth, h as getElementWidthUnless, W as getInputValueType, S as getShadowRootOrElse, q as getWindowDimensions, A as hasAttributeAndIsNotEmpty, c as isFocusable, m as isHTMLElement, a as isScrollable, C as isVisible, s as queryAllFocusable, j as queryChildFromLightOrShadowDom, B as querySelectorRoots, w as removeAttributeValue, y as removeAttributes, p as setAttributes, g as setOrRemoveAttribute, R as spanWrapper, f as tabFlowSelectors, v as windowIsAboveMobileBreakpoint };
