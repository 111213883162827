import e from "ramda/es/curryN";
import { isBrowser as t } from "./environment.js";
import { elementExists as r, existsInWindow as o } from "./exists.js";
import { setupCDSGlobal as s } from "./global.js";
import { isStorybook as m } from "./framework.js";
import { LogService as i } from "../services/log.service.js";
const n = e(3, (e, t, o) => {
  r(e) && !m() ? i.warn(e + " has already been registered") : (o.define(e, t), s(), window && !Object.keys(window.CDS._state.elementRegistry).some(t => t === e) && (window.CDS._state.elementRegistry = {
    ...window.CDS._state.elementRegistry,
    [e]: {}
  }));
});
function w(e, r) {
  t() && o(["customElements"]) && n(e, r, window.customElements);
}
export { w as registerElementSafely };
