/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const i = (i, e) => "method" === e.kind && e.descriptor && !("value" in e.descriptor) ? {
    ...e,
    finisher(n) {
      n.createProperty(e.key, i);
    }
  } : {
    kind: "field",
    key: Symbol(),
    placement: "own",
    descriptor: {},
    originalKey: e.key,
    initializer() {
      "function" == typeof e.initializer && (this[e.key] = e.initializer.call(this));
    },
    finisher(n) {
      n.createProperty(e.key, i);
    }
  },
  e = (i, e, n) => {
    e.constructor.createProperty(n, i);
  };
function n(n) {
  return (t, o) => void 0 !== o ? e(n, t, o) : i(n, t);
}
export { n as property };
