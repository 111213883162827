import "@cds/core/polyfills";
import e from "./base/base.element.scss.js";
export { CdsBaseButton } from "./base/button.base.js";
export { CdsBaseFocusTrap } from "./base/focus-trap.base.js";
export { hslToRgb, rgbToHex } from "./utils/color.js";
export { ActiveController, active } from "./controllers/active.controller.js";
export { AriaButtonController, ariaButton } from "./controllers/aria-button.controller.js";
export { AriaDisabledController, ariaDisabled } from "./controllers/aria-disabled.controller.js";
export { AriaExpandedController, ariaExpanded } from "./controllers/aria-expanded.controller.js";
export { AriaPopupController, ariaPopup } from "./controllers/aria-popup.controller.js";
export { AriaPopupTriggerController, ariaPopupTrigger } from "./controllers/aria-popup-trigger.controller.js";
export { AriaPressedController, ariaPressed } from "./controllers/aria-pressed.controller.js";
export { AriaSelectedController, ariaSelected } from "./controllers/aria-selected.controller.js";
export { ButtonAnchorController, buttonAnchor } from "./controllers/button-anchor.controller.js";
export { ButtonSubmitController, buttonSubmit } from "./controllers/button-submit.controller.js";
export { ClosableController, closable } from "./controllers/closable.controller.js";
export { FirstFocusController, firstFocus } from "./controllers/first-focus.controller.js";
export { InlineFocusTrapController, focusTrap } from "./controllers/inline-focus-trap.controller.js";
export { addClassnames, getCssPropertyValue, hasClassnames, isCssPropertyName, pxToRem, removeClassnames, removeClassnamesUnless, setCssPropertyValue, supportsAdoptingStyleSheets, unsetElementStyles, updateElementStyles } from "./utils/css.js";
export { addAttributeValue, assignSlotNames, coerceBooleanProperty, contextMenuClick, createFragment, focusableSelectors, getElementWidth, getElementWidthUnless, getInputValueType, getShadowRootOrElse, getWindowDimensions, hasAttributeAndIsNotEmpty, isFocusable, isHTMLElement, isScrollable, isVisible, queryAllFocusable, queryChildFromLightOrShadowDom, querySelectorRoots, removeAttributeValue, removeAttributes, setAttributes, setOrRemoveAttribute, spanWrapper, tabFlowSelectors, windowIsAboveMobileBreakpoint } from "./utils/dom.js";
export { registerElementSafely } from "./utils/registration.js";
export { AriaGridController, ariaGrid } from "./controllers/aria-grid.controller.js";
export { AriaModalController, ariaModal } from "./controllers/aria-modal.controller.js";
export { AriaMultiSelectableController, ariaMultiSelectable } from "./controllers/aria-multiselectable.controller.js";
export { DraggableListController, draggableList } from "./controllers/draggable-list.controller.js";
export { GridRangeSelectionController, gridRangeSelection } from "./controllers/grid-range-selection.controller.js";
export { KeyNavigationGridController, keyNavigationGrid } from "./controllers/key-navigation-grid.controller.js";
export { KeyNavigationListController, keyNavigationList } from "./controllers/key-navigation-list.controller.js";
export { LayerController, layer } from "./controllers/layer.controller.js";
export { ResponsiveController, responsive } from "./controllers/responsive.controller.js";
export { ScrollableVisibilityController, scrollableVisibility } from "./controllers/scrollable-list-visibility.controller.js";
export { TouchController, touch } from "./controllers/touch.controller.js";
export { TriggerController, triggerable } from "./controllers/trigger.controller.js";
export { animate } from "./decorators/animate.js";
export { querySlot, querySlotAll } from "./decorators/query-slot.js";
export { getDefaultOptions, property, requirePropertyCheck, state } from "./decorators/property.js";
export { customElement } from "./decorators/element.js";
export { EventEmitter, event } from "./decorators/event.js";
export { SpreadProps, spreadProps } from "./directives/spread-props.js";
export { id } from "./decorators/id.js";
export { getI18nUpdateStrategy, getI18nValues, i18n } from "./decorators/i18n.js";
export { globalStyle } from "./decorators/global-style.js";
export { CDS_FOCUS_TRAP_DOCUMENT_ATTR, FocusTrapTrackerService } from "./services/focus-trap-tracker.service.js";
export { GlobalStateService } from "./services/global.service.js";
export { I18nService, componentStringsDefault } from "./services/i18n.service.js";
export { LogService, notProductionEnvironment } from "./services/log.service.js";
export { sleep } from "./utils/async.js";
export { describeElementByElements, hasAriaLabelTypeAttr } from "./utils/a11y.js";
export { arrayHead, arrayRemoveAllInstances, arrayRemoveFirstInstance, arrayRemoveLastInstance, arrayTail, arrayToObject, asyncGroupArray, groupArray, nextInArray, previousInArray } from "./utils/array.js";
export { isSafari, isWindows } from "./utils/browser.js";
export { KeyCodeService, getKeycodeFromRegistry } from "./services/keycodes.service.js";
export { anyPassOrAnyFail, returnOrFallthrough } from "./utils/conditional.js";
export { getEnumValueFromStringKey } from "./utils/enum.js";
export { elementExists, existsIn, existsInWindow } from "./utils/exists.js";
export { focusElement, focusable, getActiveElement, ignoreFocus, initializeKeyListItems, onEscape, onFocusOut, setActiveKeyListItem, simpleFocusable } from "./utils/focus.js";
export { getAngularJSVersion, getAngularVersion, getReactVersion, getVueVersion, isStorybook } from "./utils/framework.js";
export { getElementLanguageDirection } from "./i18n/utils.js";
export { checkNextPosition, getBestPositionForPreferences, getCrossAxisOrderOfPreference, getCrossAxisPosition, getMainAxisPosition, getMainAxisPositionOrViolation, getNextDefaultPosition, getNextPosition, getOrientationTuple, getPointerAlignment, getPointerPosition, getPopupOffsetOrDefaultOffsets, getPopupPosition, getPositionConfig, getPositionOrViolationFromCrossAxis, getPositions, setPopupPosition, testCrossAxisPosition, testMainAxisPosition } from "./positioning/utils.js";
export { Positions } from "./positioning/interfaces.js";
export { allAre, allAreDefined, allPropertiesPass, anyOrAllPropertiesPass, anyPropertiesPass, convertAttributeStringValuesToValue, convertStringPropValuePairsToTuple, createId, deepClone, doesPropertyPass, getEnumValues, getFromObjectPath, hasPropertyChanged, hasStringPropertyChanged, hasStringPropertyChangedAndNotNil, isMap, isNil, isNilOrEmpty, isNumericString, isObject, isObjectAndNotNilOrEmpty, isString, isStringAndNotNilOrEmpty, isStringOrNil, mergeObjects, objectNaiveDeepEquals } from "./utils/identity.js";
export { KeyNavigationCode, getModifierKeysFromKeyCombo, keyWasEvented, onAnyKey, onKey, onKeyCombo, removeModifierKeysFromKeyCombo, validKeyNavigationCode } from "./utils/keycodes.js";
export { childrenUpdateComplete, propUpdated, renderAfter, renderBefore, syncDefinedProps, syncProps, syncPropsForAllItems } from "./utils/lit.js";
export { compareSumTo, getMillisecondsFromSeconds, getOffesetDifference, sumAndSubtract } from "./utils/math.js";
export { getElementStorybookArgTypes, getElementStorybookArgs } from "./utils/metadata.js";
export { calculateOptimalLayout, elementResize, elementVisible } from "./utils/responsive.js";
export { isTshirtSize, updateEquilateralSizeStyles } from "./utils/size.js";
export { camelCaseToKebabCase, capitalizeFirstLetter, convertStringPropertyToObjectConfig, cssGroup, getNumericValueFromCssSecondsStyleValue, interpolateNaively, isPrefixedBy, isPrefixedOrSuffixedBy, isSuffixedBy, kebabCaseToCamelCase, kebabCaseToPascalCase, pluckPixelValue, pluckValueFromStringUnit, propertiesGroup, removePrefix, removePrefixOrSuffix, removeSuffix, replaceWord, setPropStyles, setStyles, transformSpacedStringToArray, transformToSpacedString, transformToString, transformToUnspacedString, trimExtraWhitespace } from "./utils/string.js";
export { browserFeatures } from "./utils/supports.js";
export { getChildren, getFlattenedDOMTree, getFlattenedFocusableItems } from "./utils/traversal.js";
export { getElementUpdates, listenForAttributeChange, listenForAttributeListChange, onChildListMutation, onFirstInteraction, stopEvent } from "./utils/events.js";
export { EventSubject } from "./utils/event-subject.js";
import "./interfaces/interfaces.js";
export { AnimationStatus, CLARITY_MOTION_ENTER_LEAVE_PROPERTY, CLARITY_MOTION_FALLBACK_DURATION_IN_MS, CLARITY_MOTION_FALLBACK_EASING, CLARITY_MOTION_REVERSE_ANIMATION_LABEL, CLARITY_MOTION_REVERSE_ANIMATION_SUFFIX, PRIVATE_ANIMATION_STATUS_ATTR_NAME } from "./motion/interfaces.js";
export { ClarityMotion } from "./motion/motion.service.js";
export { animationIsReversed, extractAnimationNameIfReversed, filterAnimationsByUpdatedProperties, flattenAndSortAnimations, getAnimationConfigForPropertyValue, getAnimationFromOverrideOrDecorator, getAnimationKeyframesOrPropertyIndexedFrames, getAnimationPromiseInstructions, getAnimationTarget, getHidingAndNonHidingPropertyAnimations, getInlineOverride, getPropertyAnimations, resolveAnimationEndStatus, reverseAnimation, reverseAnimationConfig, runPropertyAnimations, setAnimationConfigOptions, setAnimationDuration, setAnimationEasing, setAnimationProperty, setAnimationStartStatus, sizeDimensionKeyframes, zeroOutAnimationConfig } from "./motion/utils.js";
export { AnimationModalEnterConfig, AnimationModalEnterName } from "./motion/animations/cds-modal-enter.js";
export { AnimationAccordionPanelOpenConfig, AnimationAccordionPanelOpenName } from "./motion/animations/cds-accordion-panel-open.js";
export { AnimationHingeConfig, AnimationHingeName } from "./motion/animations/cds-overlay-hinge-example.js";
export { AnimationShakeConfig, AnimationShakeName } from "./motion/animations/cds-component-shake.js";
export { AnimationNavigationGroupOpenConfig, AnimationNavigationGroupOpenName } from "./motion/animations/cds-navigation-group-open.js";
export { AnimationNavigationOpenConfig, AnimationNavigationOpenName } from "./motion/animations/cds-navigation-open.js";
export { AnimationTreeItemExpandConfig, AnimationTreeItemExpandName } from "./motion/animations/cds-tree-item-expand.js";
export { AnimationResponsivePopupEnterConfig, AnimationResponsivePopupEnterName } from "./motion/animations/cds-dropdown-open.js";
const o = e;
export { o as baseStyles };
