import { isNumericString as i, isTshirtSize as e, isNilOrEmpty as t, replaceWord as n, isNil as r, unsetElementStyles as a, pxToRem as u, updateElementStyles as l } from "@cds/core/internal";
var d;
function c(n) {
  switch (!0) {
    case t(n):
      return d.NilSizeValue;
    case e(n):
      return d.ValidSizeString;
    case i(n):
      return d.ValidNumericString;
    default:
      return d.BadSizeValue;
  }
}
function h(i) {
  return t(i) ? "" : n(i, "fit");
}
function s(i, e) {
  return !r(i) && i.indexOf("fit") > -1 ? [["width", "auto"], ["height", "auto"], ["min-width", e], ["min-height", e]] : [["width", e], ["height", e], ["min-width", e], ["min-height", e]];
}
function S(i, e) {
  let t = "";
  switch (c(h(e))) {
    case d.ValidNumericString:
      return t = u(parseInt(e)), void l(i, ...s(e, t));
    case d.ValidSizeString:
    case d.NilSizeValue:
      return void a(i, "width", "height", "min-width", "min-height");
    case d.BadSizeValue:
    default:
      return;
  }
}
!function (i) {
  i.BadSizeValue = "bad-value", i.ValidSizeString = "value-is-string", i.ValidNumericString = "value-is-numeric", i.NilSizeValue = "value-is-nil";
}(d || (d = {}));
export { d as SizeUpdateStrategies, s as getIconSizeStylesToUpdate, h as getSizeValue, c as getUpdateSizeStrategy, S as updateIconSizeStyle };
