import { property as t } from "lit/decorators/property.js";
import { camelCaseToKebabCase as r, capitalizeFirstLetter as e, kebabCaseToPascalCase as i } from "../utils/string.js";
import { LogService as n } from "../services/log.service.js";
import { getAngularVersion as o, getVueVersion as u, getReactVersion as c } from "../utils/framework.js";
import { isNilOrEmpty as s } from "../utils/identity.js";
import { coerceBooleanProperty as a } from "../utils/dom.js";
function f(t, e) {
  switch (e ? e.type : e) {
    case Array:
    case Object:
      return {
        reflect: !1,
        ...e
      };
    case String:
      return {
        reflect: !0,
        attribute: r(t),
        converter: {
          toAttribute: t => t || null
        },
        ...e
      };
    case Number:
      return {
        reflect: !0,
        attribute: r(t),
        ...e
      };
    case Boolean:
      return {
        reflect: !0,
        attribute: r(t),
        converter: {
          toAttribute: t => t ? "" : null,
          fromAttribute: t => a(t)
        },
        ...e
      };
    case Date:
      return {
        reflect: !1,
        converter: {
          fromAttribute: t => new Date(t)
        },
        ...e
      };
    default:
      return {
        ...e
      };
  }
}
function l(t, a, f) {
  const l = t.firstUpdated;
  t.firstUpdated = function (t) {
    if (f && f.required && s(this[a])) {
      const t = f.requiredMessage || function (t = "warning", n, s) {
        const a = s.toLocaleLowerCase();
        return `${e(t)}: ${n} is required to use ${a} component. Set the JS Property or HTML Attribute.\n\n` + (o() ? `Angular: <${a} [${n}]="..."></${a}>\n` : "") + (u() ? `Vue: <${a} :${n}="..."></${a}>\n` : "") + (c() ? `React: <${i(a)} ${function (t) {
          return t.startsWith("aria") ? r(t) : t;
        }(n)}={...} />\n` : "") + `HTML: <${a} ${r(n)}="..."></${a}>\n` + `JavaScript: document.querySelector('${a}').${n} = '...';\n\n`;
      }(f.required, a, this.tagName);
      if ("error" === f.required) throw Error(t);
      n.warn(t, this);
    }
    l && l.call(this, t);
  };
}
function m(r) {
  return (e, i) => (r?.required && l(e, i, r), t(f(i, r))(e, i));
}
function p(e) {
  return (i, n) => {
    const o = f(n, e);
    return o && (o.reflect = !!e?.reflect && e.reflect, o.reflect && !e?.attribute && (o.attribute = "_" + r(n))), t(o)(i, n);
  };
}
export { f as getDefaultOptions, m as property, l as requirePropertyCheck, p as state };
